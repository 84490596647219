<template>
  <header>
    <div
      class="ribbon-ka-75-logo"
      v-if="!isErrorPage"
    />
    <div
      class="header-wrapper"
      :class="{'header-wrapper-desktop': isDesktop(), 'merge-journey': isMergeJourney || isDldxPs}"
    >
      <div
        class="router-link-section-75-logo"
        v-lazy-container="{selector: 'img'}"
      >
        <a
          id="ka-logo"
          @click="onClickToHomePage()"
          :class="{disabled: canGoesToHomePage}"
        >
          <img
            id="krungsri-auto-logo"
            class="title-img"
            :data-src="require('@/assets/images/krungsri-auto-logo-80.svg')"
            alt="กรุงศรี ออโต้ พร้อมสตาร์ท"
          >
        </a>
      </div>

      <div style="display: flex; align-items: center;">
        <div
          id="btn-login"
          class="check-status-v2 gtm-btn-login"
          @click="goToLogin"
          v-if="show && !isDesktop() && !features.OUT_OF_SERVICE"
          data-ga="click_btn_check_status"
        >
          <!-- <span class="icon-check-status gtm-btn-login" data-ga="click_btn_check_status" /> -->
          <span
            class="title gtm-btn-login"
            data-ga="click_btn_check_status"
          >ตรวจสอบสถานะ</span>
        </div>
        <div
          v-click-outside="hide"
          class="menu-container-v2"
        >
          <div
            v-if="!isDesktop()"
            id="menuId"
            class="menu"
            @click="toggle"
          >
            <span
              class="icon-hamberger-v2"
              :class="{isActive: displayMenu}"
            />
          </div>
          <div
            class="menu-item-list-v2"
            :class="{isActive: displayMenu}"
          >
            <template v-for="link in menuLink">
              <a
                v-if="link.ref !== '/'"
                class="menu-item menu-link"
                :key="link.ref"
                :href="link.ref"
              >
                {{ link.display }}
              </a>
              <a
                v-else
                class="menu-item menu-link"
                :key="link.ref"
                @click="onClickToHomePage()"
              >
                {{ link.display }}
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="!isMergeJourney && !isDldxPs"
      class="header-title"
    >
      กรุงศรี ออโต้ พร้อมสตาร์ท
    </div>
  </header>
</template>

<script>
import {headerLinks} from '@/components/layout/HeaderFooterLinks'
import vClickOutside from 'v-click-outside'
import _ from 'lodash'

export default {
  name: 'Header',
  inject: ['features'],
  props: {
    isErrorPage: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      show: false,
      displayMenu: false,
      menuLink: headerLinks,
      loginPage: ['requestOtp', 'SubmitOtp', 'LoginOtp', 'RequestExclusiveOtp', 'VerifyExclusiveOtp', 'CrmSubmitOtp'],
      crmPage: ['DealListExclusive', 'ExclusiveDealDetail', 'SubExclusiveDealDetail', 'SaleSheet', 'PersonalExclusiveInfomation', 'SummaryPage', 'ExclusivePreApproval'],
      showBanner: false,
    }
  },
  updated () {
    this.show = !this.isRequestOtp() && !this.isCrmPage() && !this.isLogin() && this.isFromSms()
  },
  methods: {
    toggle () {
      this.displayMenu = !this.displayMenu
    },
    hide () {
      this.displayMenu = false
    },
    goToLogin () {
      this.$router.push('/login')
      this.$gtag.event('click', {
        event_action: 'click_check_status',
        event_label: 'check_status',
      })
    },
    isRequestOtp () {
      return this.loginPage.includes(this.$route.name)
    },
    isCrmPage () {
      return this.crmPage.includes(this.$route.name)
    },
    isLogin () {
      return this.$store.state.noDocument.login.isLoggedIn
    },
    isDesktop () {
      const userAgent = navigator.userAgent.toLocaleLowerCase()
      return !(userAgent.includes('mobile') || userAgent.includes('android'))
    },
    isFromSms () {
      if (this.$route.name !== 'productHighlight') {
        return true
      }
      return !(_.get(this.$route, 'query.ori_source', 'SMS').toUpperCase() === 'SMS')
    },
    isHome () {
      return this.$route.name === 'home' || this.$route.name === 'homePage' || this.$route.name === 'productHighlight'
    },
    goToDownloadAppGo () {
      const userAgent = navigator.userAgent.toLocaleLowerCase()
      let url
      if (userAgent.includes('android')) {
        url = 'https://play.google.com/store/apps/details?id=com.krungsriauto.superapp.go'
      } else if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
        url = 'https://apps.apple.com/th/app/go-by-krungsri-auto/id1489440875'
      } else {
        url = '#'
      }
      window.open(url, '_self')
    },
    closeBanner () {
      this.showBanner = false
    },
    onClickToHomePage () {
      this.displayMenu = false
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: this.$route.query,
        })
      }
    },
  },
  computed: {
    canGoesToHomePage () {
      return this.$route.name === 'UploadingPage' || this.$route.name === 'ServiceNotAvailablePage'
    },
    isMergeJourney () {
      return (
        (window.features.MERGE_JOURNEY && this.$route.name === 'home') ||
        this.$route.name === 'ResultPromptstart' ||
        this.$route.path === '/preApproval' ||
        this.$route.path === '/crm/preapprove/result' ||
        this.$route.name === 'DigitalLendingLeadForm' ||
        this.$route.name === 'DigitalLendingLeadFormThankyou' ||
        this.$route.name === 'DigitalLendingResult'
      )
    },
    isDldxPs () {
      return (
        window.features.MERGE_JOURNEY &&
        (this.$route.name === 'ScreeningQuestionIntro' ||
          this.$route.name === 'PlanToBuy' ||
          this.$route.name === 'ReadyToBuy' ||
          this.$route.name === 'GetToKnowProduct' ||
          this.$route.name === 'DigitalLendingResult')
      )
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
}
</script>

<style lang="scss">
@import '~styles/main';

@mixin icon {
  width: 23px;
  height: 23px;
}

header {
  width: 100%;
  max-width: 1024px;
  margin: 8px auto;
  @media (min-width: 1024px) {
  }
  .ribbon-ka-75-logo {
    width: 100%;
    height: 64px;
    position: absolute;
  }

  .header-wrapper {
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    align-items: center;
  }

  .header-wrapper-desktop {
    padding-bottom: 20px;
  }

  .router-link-section-75-logo {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    z-index: 15;
    @media (max-width: 320px) {
      margin-right: 16px;
    }
    img {
      max-width: 142.29px;
      width: 100%;
    }
    .disabled {
      pointer-events: none;
      cursor: default;
    }
  }

  .check-status-v2 {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    width: 101px;
    z-index: 10;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.64;
    color: #6e5f5e;
    border-radius: 4px;
    border: solid 2px #ffd400;
    background-color: #ffd400;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 14px;
      font-weight: 400;
    }
    @media (max-width: 320px) {
      right: -12px;
      font-size: 12px;
    }

    .title {
      padding: 0 8px;
      white-space: nowrap;
    }
  }

  .header-banner-go-download {
    grid-column: 1 / 5;
    grid-row: 3 / 4;
    padding-top: 12px;
    padding-left: 8px;
    padding-right: 8px;

    img {
      width: 100%;
    }
  }

  .header-banner-go-download-close {
    padding-top: 12px;
    grid-column: 5;
    grid-row: 3 / 4;
    padding-right: 8px;
    display: grid;
    justify-content: center;
    align-content: center;
  }

  .header-title {
    grid-column: 2 / 6;
    grid-row: 4;
    font-size: 22px;
    font-weight: bold;
    line-height: 1.82;
    color: hsl(4, 8%, 40%);
    padding: 0 20px;
    padding-top: 16px;
    margin: 0 auto;
    max-width: 580px;
    align-items: center;
  }
  .menu-container-v2 {
    grid-column: 4 / 4;
    grid-row: 1 / 2;
    z-index: 10;
    align-items: center;
    padding-left: 8px;

    .menu {
      display: flex;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.64;
      margin-top: 0;
      float: right;
      cursor: pointer;

      .title {
        padding: 0 8px;
      }
    }
  }

  .menu-item-list-v2 {
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 56px 20px 0;
    box-shadow: 0 20px 20px 0 hsla(0, 0%, 76%, 0.2);
    background-color: hsl(0, 0%, 100%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-out;

    &.isActive {
      opacity: 1;
      visibility: visible;
    }

    .menu-item {
      text-decoration: none;
      display: block;
      padding: 8px 0;
      font-size: 16px;
      font-weight: 500;
      color: hsl(4, 8%, 40%);
      line-height: 40px;
      border-bottom: 1px solid hsla(0, 0%, 0%, 0.04);
      transition: color 0.2s linear;

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: #ccaa00;
      }
    }
    .menu-item:last-of-type {
      border-bottom: {
        width: 0px;
      }
    }
  }
  .icon-hamberger-v2 {
    @include icon;
    background: url('~@/assets/icons/icon-hamberger.svg') no-repeat;
    background-position: center;
    width: 32px;
    height: 32px;

    &.isActive {
      background: url('~@/assets/icons/icon-hamberger.svg') no-repeat;
      background-position: center;
    }
  }

  .icon-hamberger {
    @include icon;
    background: url('~@/assets/icons/icon-hamberger.svg') no-repeat;

    &.isActive {
      background: url('~@/assets/icons/icon-hamberger.svg') no-repeat;
    }
  }

  .icon-check-status {
    @include icon;
    background: url('~@/assets/icons/icon-check-status.svg') no-repeat;
  }
}
</style>
